.chart {
  height: 700px;
  width: 1000px;
  border-radius: 25px;
}

@media only screen and (max-width: 1700px) {
  .chart {
    height: 500px;
    width: 700px;
    border-radius: 20px;
  }
}
