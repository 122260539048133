.mainContainer {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(to bottom, #090909, #212121, #090909);
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftContainer {
  width: 15%;
}
.middleContainer {
  width: 70%;
  display: flex;
}

.middleLeft {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .middleLeft {
    display: none;
  }
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
  border-color: #212121;
  border-style: solid;
  border-width: 5px;
}

.middleRight {
  width: 50%;
  text-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .middleRight {
    width: 100%;
  }
}
.title {
  font-size: 28px;
  font-family: "Lato";
  color: white;
  margin-bottom: 15px;
  margin-top: 15px;
}

.text {
  font-size: 18px;
  color: white;
  word-break: break-word;
}
@media only screen and (max-width: 600px) {
  .text {
    font-size: 14px;
    color: white;
  }
}

.rightContainer {
  width: 15%;
}
