.mainContainer {
  background-image: linear-gradient(to bottom, #000000, #2a2a33, #000000);
  display: block;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4364px;
}

.title {
  color: white;
  font-size: 50px;
}
.text {
  color: white;
  font-size: 24px;
}
