.mainContainer {
  max-width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
  background-color: #242529;
}
.leftHeader {
  width: 20%;
  height: 400px;
}
.middleHeader {
  width: 60%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nftHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.nftHeaderImage {
  width: 1000px;
  height: 381.5px;
  object-fit: fill;
  border-radius: 20px;
  border-color: #2b2a2a;
  border-style: solid;
  border-width: 1px;
  transition: border-color 2s ease;
}
.nftHeaderImage:hover {
  border-color: #ff0000;
}

.subHeader {
  height: 200px;
  width: 95%;
  margin-top: -220px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.headerNormalImage {
  max-width: 180px;
  height: auto;
  object-fit: contain;
  border-color: rgb(97, 96, 96);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
}
.headerTitle {
  color: white;
  font-size: 30px;
  font-weight: 600;
}

.rightHeader {
  width: 20%;
  height: 400px;
}

.menuHeader {
  height: 80px;
}
.marketSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 100%;
}

.bodyContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.leftMenuBar {
  width: 220px;
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lato";
  font-size: 14px;
  background-color: #242529;
  color: white;
  border-right: 1px solid #686852;

  text-align: center;
}
.marketBtn {
  font-family: "Lato";
  font-size: 12px;
  width: 120px;
  margin-bottom: 5px;
}
.marketLogo {
  width: 20px;
  height: 20px;
}
.selector {
  display: flex;

  align-items: center;
}

.nftSection {
  width: 84%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.bodyHeader {
  color: white;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  width: 100%;
  height: 50px;
}
.cardContainer {
  color: white;
  font-size: 20px;
  font-weight: 900;
  font-family: "Lato";
  border-color: rgb(51, 50, 50);
  display: block;
  width: 280px;
  height: 340px;
  background-image: linear-gradient(to bottom, #222222, #444444, #111111);
  margin: 10px;
  border-radius: 20px;
  border-style: solid;
  transition: transform 0.7s ease;
}
.cardContainer:hover {
  border-color: rgb(217, 238, 245);
  transform: scale(1.07);
  cursor: pointer;
}

.bannerImage {
  width: 280px;
  min-height: 200px;
  max-height: 200px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.cardtitle {
  display: flex;

  align-items: center;
  width: 90%;
  height: 50px;
  color: white;
  font-size: 18px;
  font-family: "Lato";
  font-weight: 900;
  padding-left: 20px;
}

.subTitle {
  height: 30px;
  color: gray;
  font-size: 14px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftContainer {
  width: 50%;
  height: 100%;
  padding-left: 20px;
}
.rightContainer {
  width: 50%;
  height: 100%;
  padding-left: 20px;
}

.text {
  width: 100%;
  display: flex;
  color: white;
  font-size: 16px;
  font-weight: 900;
}
