.mainContainer {
  background-image: linear-gradient(to bottom, #000000, #2a2a33, #000000);
  width: 100%;
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
}

.leftContainer {
  width: 20%;
}

.middleContainer {
  width: 60%;
  display: flex;
}

.middleLeft {
  width: 50%;
  padding-right: 30px;
}

@media only screen and (max-width: 1200px) {
  .middleLeft {
    width: 100%;
    padding: 15%;
  }
}

.middleRight {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .middleRight {
    width: 0%;
  }
}

.rightContainer {
  width: 20%;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

@media only screen and (max-width: 1200px) {
  .image {
    display: none;
  }
}

.title {
  color: white;
  font-size: clamp(1rem, 2vw + 1rem, 3rem);
  font-family: "Agency FB";
}

.text {
  color: white;
  font-size: clamp(0.5rem, 2vw + 0.3rem, 1.25rem);
}

.button {
  color: #ffffff;
  font-family: "Familjen Grotesk", Helvetica;
  align-items: center;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 18px 32px;
  position: relative;
  height: 20px;
  max-width: 160px;
}
.button:hover {
  scale: 1.1;
}
