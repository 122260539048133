import React from "react";

const Cex = () => {
  return (
    <div>
      <div>CEX</div>
    </div>
  );
};

export default Cex;
