import React from "react";

const Web3Games = () => {
  return (
    <div>
      <div>Web3Games</div>
    </div>
  );
};

export default Web3Games;
