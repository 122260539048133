.mainContainer {
  height: 35px;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.tickContainer {
  max-width: 100px;
  display: flex;
  margin-right: 15px;
  color: rgb(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 0.5px;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (min-width: 400px) {
  .tickContainer {
    max-width: 100px;
    display: flex;
    margin-right: 5px;
  }
}

.symbolText {
  color: goldenrod;
  font-size: 18px;
  margin-right: 15px;
}

@media only screen and (min-width: 400px) {
  .symbolText {
    color: goldenrod;
    font-size: 16px;
    margin-right: 5px;
  }
}

.priceText {
  color: white;
  font-size: 18px;
}
@media only screen and (min-width: 400px) {
  .priceText {
    color: white;
    font-size: 16px;
  }
}
