.mainContainer {
  background-image: linear-gradient(to bottom, #111111, #333333, #111111);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.title {
  color: white;
  font-size: 24px;
}
.cardContainer {
  color: rgb(189, 189, 189);
  display: block;
  align-items: center;
  width: 400px;
  height: 250px;
  margin: 10px;
  border-radius: 20px;
  padding: 3px;
  background-image: linear-gradient(to right, #111111, #222222);
  border-style: solid;
  border-width: 5px;
}
.bannerImage {
  font-size: 30px;
  background-color: #222222;
  max-width: 385px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  max-height: 130px;
  object-fit: contain;
}
