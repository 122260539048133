import React from "react";

const Dex = () => {
  return (
    <div>
      <div>DEX</div>
    </div>
  );
};

export default Dex;
