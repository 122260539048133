.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: linear-gradient(to bottom, #111111, #333333, #111111);
}

.leftContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to bottom, #050505, #222222, #050505);
}
@media only screen and (max-width: 1100px) {
  .leftContainer {
    display: none;
  }
}

.coinCard {
  height: 70px;
  width: 280px;
  border-radius: 20px;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.coinLogo {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
}
.coinGraph {
  color: white;
}
.coinName {
  width: 45%;
}
.coinPrice {
  width: 30%;
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.middleContainer {
  color: white;
  display: block;
  justify-content: center;
  width: 60%;
  height: 100%;
}
@media only screen and (max-width: 1100px) {
  .middleContainer {
    width: 100%;
  }
}

.rightContainer {
  color: white;
  display: flex;
  justify-content: center;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to bottom, #050505, #222222, #050505);
}

@media only screen and (max-width: 1100px) {
  .rightContainer {
    display: none;
  }
}

.chartContainer {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.rssFeed {
  height: 1500px;
  width: 300px;
}
